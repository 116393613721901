<template>
  <div class="main-wrapper">
    <Header/>
    <div class="flex-grow-1">
      <v-container class="justify-center">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div class="flex-grow-1 mt-12 elevation-2 rounded white py-6 px-6" style="max-width: 500px;">
              <LoginForm v-if="loginState === 'login'" :isEmailSent="isEmailSent" @changeLoginState="changeLoginState($event)"/>
              <ForgotPasswordForm v-else @changeSentStatus="changeSentStatus($event)" @changeLoginState="changeLoginState($event)"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import Header from '@/components/common/Header';
  import LoginForm from '@/components/LoginForm';
  import ForgotPasswordForm from '@/components/ForgotPasswordForm';

  export default {
    name: "Login",
    components: {ForgotPasswordForm, LoginForm, Header},

    data() {
      return {
        loginState: 'login',
        isEmailSent: false
      };
    },

    methods: {
      changeLoginState(newLoginState) {
        this.loginState = newLoginState;
      },
      changeSentStatus(value) {
        this.isEmailSent = value;
      }
    }
  };
</script>
