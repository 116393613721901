<template>
  <div>
    <v-form ref="loginForm" v-model="loginFromValid" class="mb-2">
      <h1 class="text-h4 text-center mb-6">{{ 'login'|localize }}</h1>
      <v-alert v-if="isEmailSent" type="success" dense text>{{ 'sentEmailNote'|localize }}</v-alert>
      <h6 class="text-subtitle-2 mb-2">{{ 'email'|localize }} *</h6>
      <v-text-field
        v-model="email"
        dense
        outlined
        background-color="white"
        :placeholder="'enterEmail'|localize"
        :rules="[validationRules.required, validationRules.email]"
        persistent-hint
      />
      <h6 class="text-subtitle-2 mb-2">{{ 'password'|localize }} *</h6>
      <v-text-field
        v-model="password"
        type="password"
        dense
        outlined
        background-color="white"
        :placeholder="'enterPassword'|localize"
        :rules="[validationRules.required]"
      />
    </v-form>
    <v-btn class="mb-4" block color="primary" depressed rounded :loading="loading" :disabled="loading" @click="login">
      {{ 'login'|localize }}
    </v-btn>
    <v-btn text block color="primary" depressed rounded @click="$emit('changeLoginState', 'password')">
      {{ 'forgotYourPassword'|localize }}?
    </v-btn>
    <v-dialog
      v-model="companyDialog"
      width="400"
      overlay-color="black"
      :overlay-opacity="0.3"
      @click:outside="declineCompanyDialog"
    >
      <v-card class="text-center px-6">
        <v-card-title class="text-h5 d-block pt-6 pb-6">{{ 'selectCompanyNote'|localize }}</v-card-title>
        <v-card-text class="text-body-1 pb-0">
          <v-select
            background-color="white"
            v-model="selectedCompany"
            :items="companies"
            item-text="name"
            return-object
            hide-details
            dense
            outlined
            :placeholder="'company'|localize"
          />
        </v-card-text>
        <v-card-actions class="py-6 px-0">
          <v-row>
            <v-col>
              <v-btn color="primary" block depressed :disabled="!selectedCompany" rounded @click="continueLogin">
                {{ 'continue'|localize }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import validationRules from '@/helpers/validationRules';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import {ROLES} from '@/enums/roles';

  export default {
    name: "LoginForm",
    props: ['isEmailSent'],

    data() {
      return {
        validationRules,
        loginFromValid: false,
        email: '',
        password: '',
        loading: false,
        companyDialog: false,
        companies: [],
        selectedCompany: null,
        userData: null
      };
    },

    computed: {
      locale() {
        return this.$store.getters.locale;
      }
    },

    methods: {
      queryData() {
        let queryData = {};
        queryData.email = this.email;
        queryData.password = this.password;
        queryData.locale = this.locale;
        return queryData;
      },
      declineCompanyDialog() {
        this.selectedCompany = null;
        this.userData = null;
      },
      async login() {
        if (!this.$refs.loginForm.validate()) return;
        try {
          this.loading = true;
          let userData = await api.login(this.queryData());
          this.userData = {...userData.data};
          if (this.userData.role === ROLES.COMPANY) {
            this.loading = false;
            if (this.userData.companies.length === 1) {
              this.userData.company = this.userData.companies[0];
              methods.saveUserData(this.userData);
              methods.afterLogin(this.locale);
            } else {
              this.companies = this.userData.companies;
              this.companyDialog = true;
            }
          } else {
            methods.saveUserData(this.userData);
            methods.afterLogin(this.locale);
          }
        } catch (e) {
          this.loading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      continueLogin() {
        this.userData.company = this.selectedCompany;
        methods.saveUserData(this.userData);
        methods.afterLogin(this.locale);
      }
    }
  };
</script>
