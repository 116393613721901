<template>
  <div>
    <v-form ref="emailForm" v-model="emailFromValid" class="mb-2">
      <h1 class="text-h4 text-center mb-3">{{ 'forgotPassword'|localize }}?</h1>
      <div class="text-center text-subtitle-2 font-weight-regular mb-6">{{ 'forgotPasswordNote'|localize }}</div>
      <h6 class="text-subtitle-2 mb-2">{{ 'email'|localize }} *</h6>
      <v-text-field
        v-model="email"
        dense
        outlined
        background-color="white"
        :placeholder="'enterEmail'|localize"
        :rules="[validationRules.required, validationRules.email]"
      />
    </v-form>
    <v-btn
      class="mb-4"
      block
      color="primary"
      depressed
      rounded
      :loading="sendLoading"
      :disabled="sendLoading"
      @click="sendEmail"
    >
      {{ 'send'|localize }}
    </v-btn>
    <v-btn text block color="primary" depressed rounded :disabled="sendLoading" @click="backToLogin">
      {{ 'backToLogin'|localize }}
    </v-btn>
  </div>
</template>

<script>
  import validationRules from '@/helpers/validationRules';
  import api from '@/api/api';
  import methods from '@/helpers/methods';

  export default {
    name: "ForgotPasswordForm",

    data() {
      return {
        validationRules,
        sendLoading: false,
        emailFromValid: false,
        email: '',
      };
    },

    computed: {
      locale() {
        return this.$store.getters.locale;
      }
    },

    methods: {
      backToLogin() {
        this.$emit('changeLoginState', 'login');
      },
      queryData() {
        let queryData = {};
        queryData.locale = this.locale;
        queryData.email = this.email;
        return queryData;
      },
      async sendEmail() {
        if (!this.$refs.emailForm.validate()) return;
        try {
          this.sendLoading = true;
          await api.sendResetLink(this.queryData());
          this.sendLoading = false;
          this.$emit('changeSentStatus', true);
          this.backToLogin();
        } catch (e) {
          this.sendLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    }
  };
</script>
